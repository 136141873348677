import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { tablet, useQuery } from 'styles/breakpoints';
import { DynamicImage, PrimaryButton, Seo } from 'components';
import Header from 'components/Header';
import { useQuizData } from 'utils/hooks';
import { useRouter } from 'apis/history';
import ResultsProgress from 'components/ResultsProgress';
import { Quiz, QuizKey } from 'types/quiz';

interface PlanInfoListItem {
  title: string;
  text: string;
  emoji: string;
}

interface PlanInfoTypes {
  list: PlanInfoListItem[];
  buttonTitle: string;
  onContinue: () => void;
}

const PlanInfo: FC<PlanInfoTypes> = ({ list, buttonTitle, onContinue }) => {
  const [loading, setLoading] = useState(false);
  const quiz =
    new URLSearchParams(location.search).get(QuizKey.Qz) ?? Quiz.Main;
  const { goToCheckout } = useRouter();

  const handleClick = () => {
    setLoading(true);
    if (quiz === Quiz.Main) {
      return goToCheckout({ discount: false });
    }

    onContinue();
  };

  return (
    <Container>
      <ListContainer>
        {list.map((item, index) => (
          <ListItem key={index}>
            <EmojiContainer>
              <Emoji src={item.emoji} alt="" />
            </EmojiContainer>
            <TextContainer>
              <ListTitle>{item.title}</ListTitle>
              <ListText>{item.text}</ListText>
            </TextContainer>
          </ListItem>
        ))}
      </ListContainer>
      <ButtonContainer>
        <ContinueButtonStyled onClick={handleClick} loading={loading}>
          {buttonTitle}
        </ContinueButtonStyled>
      </ButtonContainer>
    </Container>
  );
};

export default PlanInfo;

const EmojiContainer = styled.section`
  width: 1.5rem;
  height: 1.5rem;
`;

const Emoji = styled(DynamicImage)`
  width: 1.5rem;
  height: 1.5rem;
`;

const Outter = styled.section`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 3;
`;

const Container = styled.div`
  max-width: 28.75rem;
  margin: 0 auto;
  width: 100%;
  @media ${tablet} {
  }
`;

const ButtonContainer = styled.section`
  width: 100%;
  @media ${tablet} {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem 1rem;
    width: 100%;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 80%;
      background: #ffffff;
      filter: blur(4px);
      z-index: -1;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 80%;
      background: #ffffff;
      z-index: -1;
    }
  }
`;

const ContinueButtonStyled = styled(PrimaryButton)`
  margin: 1.5rem auto 0;
  @media ${tablet} {
    max-width: 28.75rem;
    margin: 0 auto;
  }
`;

const ListContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
`;

const ListItem = styled.section`
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  border-radius: 0.625rem;
  border: 1px solid #f5f5f5;
  background: #f9f9f9;
  padding: 1rem 0.75rem;
`;

const ListTitle = styled.h4`
  color: #000;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  @media ${tablet} {
  }
`;

const ListText = styled.p`
  color: #777;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  @media ${tablet} {
  }
`;

const TextContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;
