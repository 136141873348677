import React, { FC } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { DynamicImage, Text } from 'components';
import { tablet, useQuery } from 'styles/breakpoints';
import { AppState } from 'state/types';
import { getLocalisedProduct } from 'utils/localization';
import { Product } from 'types/product';
import { link } from 'fs';
import DownloadImg from 'assets/icons/success/download-icon.svg';
import { config } from 'config';

interface OrderSummaryProps {
  title: string;
  upsellsKeys: string[];
  bundlePhoto: { [key: string]: number };
}

const Container = styled.div`
  max-width: 71.25rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.5rem 1rem;
  @media ${tablet} {
    padding: 2.5rem 1rem;
  }
`;

const Heading = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 2.5rem;

  @media ${tablet} {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
`;

const SummaryContainer = styled.div`
  width: 100%;
  max-width: 40rem;
  display: flex;
  flex-direction: column;
`;

const OrderItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5rem;
`;

const InnerItem = styled.section`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  width: 100%;
`;

const LifetimeSection = styled.section`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0 0 4.8rem;
  @media ${tablet} {
    padding: 1rem 0 0 2.7rem;
  }
`;

const LifetimeTitle = styled.p`
  color: #000;
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 400;
  line-height: 120%;
  width: 100%;
  @media ${tablet} {
    font-size: 0.875rem;
  }
`;

const TotalItemContainer = styled(OrderItemContainer)`
  border-top: 1px solid rgba(119, 119, 119, 0.1);
  padding: 1.5rem 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const OrderSummary: FC<OrderSummaryProps> = ({
  title,
  upsellsKeys,
  bundlePhoto,
}) => {
  const { isTablet } = useQuery();
  const { selected_plan, upsell_products } = useSelector(
    (state: AppState) => state.user,
  );

  const selectedProduct = getLocalisedProduct(selected_plan);
  const localisedUpsellProducts = upsell_products.map(p =>
    getLocalisedProduct(p),
  );

  const boughtUpsell = upsell_products.map(item => item.key);

  const sortedUpsells = upsellsKeys.filter(key => !boughtUpsell.includes(key));

  const getBundlePhoto = () => {
    let result = '';
    sortedUpsells?.forEach(str => {
      if (bundlePhoto[str] !== undefined) {
        result += bundlePhoto[str].toString();
      }
    });

    return result;
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0, // Scroll to the top
      behavior: 'smooth', // Smooth scrolling
    });
  };

  const resolvePageTextContent = product => {
    if (product?.key === 'carnimeat_upsell_self-growth-bundle_otp_pdf') {
      const planKeys = upsell_products.map(up => up.key);
      return {
        isBundle: true,
        links: (
          <MultipleUpsellLinkContainer>
            {planKeys.includes(
              'carnimeat_upsell_maximizing-your-gains_otp_pdf',
            ) ? null : (
              <Download
                href="https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Fmaximizing_your_gains.pdf?alt=media&token=d3ae35fa-d704-4566-9206-32f2f0ec1c54"
                target="_blank"
              >
                <DownloadImg />
                Maximizing Your Gains: Athlete&apos;s Guide
              </Download>
            )}
            {planKeys.includes(
              'carnimeat_upsell_fasting-made-easy_otp_pdf',
            ) ? null : (
              <Download
                href="https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Ffasting_made_easy.pdf?alt=media&token=40116224-9908-4e69-b1a2-e8bba351a184"
                target="_blank"
              >
                <DownloadImg />
                Fasting Made Easy
              </Download>
            )}
            {planKeys.includes(
              'carnimeat_upsell_menstrual-based-diet_otp_pdf',
            ) ? null : (
              <Download
                href="https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Fmenstrual_cycle_based_diet_plan.pdf?alt=media&token=34cbf67c-bce5-4226-b0de-ec9ab974e987"
                target="_blank"
              >
                <DownloadImg />
                Menstrual Cycle-Based Diet Plan For Women
              </Download>
            )}
            {planKeys.includes(
              'carnimeat_upsell_habits-to-get-fitter_otp_pdf',
            ) ? null : (
              <Download
                href="https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Fhealth_habits.pdf?alt=media&token=16d922a1-d00e-490d-bf07-de01673e3374"
                target="_blank"
              >
                <DownloadImg />
                Healthy Habits to Get Fitter
              </Download>
            )}
            {planKeys.includes(
              'carnimeat_upsell_carnivore-meal-planning_otp_pdf',
            ) ? null : (
              <Download
                href="https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Fcarnivore_meal_planning_on_a_budget.pdf?alt=media&token=c1267e38-5945-4347-aa6c-312646ad4a33"
                target="_blank"
              >
                <DownloadImg />
                Carnivore Meal Planning on a Budget
              </Download>
            )}
          </MultipleUpsellLinkContainer>
        ),
      };
    }
    if (product?.key === 'carnimeat_upsell_maximizing-your-gains_otp_pdf') {
      return {
        img: 'upgrade/athletes-guider/card.png',
        link: [
          'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Fmaximizing_your_gains.pdf?alt=media&token=d3ae35fa-d704-4566-9206-32f2f0ec1c54',
        ],
      };
    }
    if (product?.key === 'carnimeat_upsell_carnivore-meal-planning_otp_pdf') {
      return {
        img: 'upgrade/budget-diet/card.png',
        link: [
          'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Fcarnivore_meal_planning_on_a_budget.pdf?alt=media&token=c1267e38-5945-4347-aa6c-312646ad4a33',
        ],
      };
    }
    if (product?.key === 'carnimeat_upsell_fasting-made-easy_otp_pdf') {
      return {
        img: 'upgrade/fasting/card.png',
        link: [
          'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Ffasting_made_easy.pdf?alt=media&token=40116224-9908-4e69-b1a2-e8bba351a184',
        ],
      };
    }
    if (product?.key === 'carnimeat_upsell_menstrual-based-diet_otp_pdf') {
      return {
        img: 'upgrade/menstrual/card.png',
        link: [
          'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Fmenstrual_cycle_based_diet_plan.pdf?alt=media&token=34cbf67c-bce5-4226-b0de-ec9ab974e987',
        ],
      };
    }
    if (product?.key === 'carnimeat_upsell_habits-to-get-fitter_otp_pdf') {
      return {
        img: 'upgrade/get-fitter/card.png',
        link: [
          'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Fhealth_habits.pdf?alt=media&token=16d922a1-d00e-490d-bf07-de01673e3374',
        ],
      };
    }
  };

  const renderUpsellProducts = (upsell_product: Product, index: number) => {
    const upsellProduct = getLocalisedProduct(upsell_product);
    const data = resolvePageTextContent(upsellProduct);
    if (upsell_product.key.includes('carnimeat_upsell_lifetime_sub')) {
      return;
    }
    return (
      <OrderItemContainer key={index}>
        <InnerItem>
          <StyledImg
            src={
              data?.isBundle
                ? `upgrade/bundle/bundle-${getBundlePhoto()}.png`
                : data?.img
            }
            alt=""
          />
          <UpsellInner>
            <ProductTitle>{upsellProduct.name}</ProductTitle>
            {!data?.isBundle ? (
              <Download href={data?.link} target="_blank">
                <DownloadImg />
                Download here
              </Download>
            ) : (
              data.links
            )}
          </UpsellInner>
          <Price>
            {upsellProduct.currency}
            {upsellProduct.finalPrice}
          </Price>
        </InnerItem>
      </OrderItemContainer>
    );
  };

  const upsellsPrice = localisedUpsellProducts.reduce(
    (sum, p) => sum + parseFloat(p?.finalPrice || '0'),
    0,
  );

  const productVisiblePrice = selectedProduct?.hasTrial
    ? selectedProduct?.discountedTrialPrice
    : selectedProduct?.finalPrice;

  const totalPrice = (
    parseFloat(Number(productVisiblePrice)) + parseFloat(upsellsPrice)
  ).toFixed(2);

  const lifetimeProduct = upsell_products.find(item =>
    item.key.includes('carnimeat_upsell_lifetime_sub'),
  );

  const localisedLifetime = getLocalisedProduct(lifetimeProduct);
  //
  return (
    <Container>
      <Heading>{title}</Heading>
      <SummaryContainer>
        <OrderItemContainer>
          <InnerItem>
            <StyledImg src="success/success-app.png" alt="" />
            <UpsellInner>
              <ProductTitle>
                {selectedProduct.name.replace('Carnimeat ', '')}
              </ProductTitle>
              <DownloadApp onClick={scrollToTop}>
                <DownloadImg />
                Download here
              </DownloadApp>
            </UpsellInner>
            <Price>
              {selectedProduct.currency}
              {Number(productVisiblePrice)}
            </Price>
          </InnerItem>
          {localisedLifetime.key && (
            <LifetimeSection>
              <LifetimeTitle>{localisedLifetime.name}</LifetimeTitle>
              <Price>
                {localisedLifetime.currency}
                {localisedLifetime.finalPrice}
              </Price>
            </LifetimeSection>
          )}
        </OrderItemContainer>
        {upsell_products.map(renderUpsellProducts)}
        <TotalItemContainer>
          <Total>Total</Total>
          <Total>
            {selectedProduct.currency}
            {totalPrice}
          </Total>
        </TotalItemContainer>
      </SummaryContainer>
    </Container>
  );
};

export default OrderSummary;

const ProductTitle = styled.p`
  color: #000;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  width: 100%;
  @media ${tablet} {
    font-size: 0.875rem;
  }
`;

const Price = styled.p`
  color: #000;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  @media ${tablet} {
    font-size: 0.875rem;
  }
`;

const Download = styled.a`
  cursor: pointer;
  color: #dc4b4b !important;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  text-decoration: underline !important;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  @media ${tablet} {
    font-size: 0.75rem;
  }
`;

const DownloadApp = styled.p`
  cursor: pointer;
  color: #dc4b4b !important;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  text-decoration: underline !important;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  &:hover {
    opacity: 0.7;
  }
  @media ${tablet} {
    font-size: 0.75rem;
  }
`;

const Total = styled.p`
  color: #000;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  @media ${tablet} {
    font-size: 1rem;
  }
`;

const UpsellInner = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  width: 100%;
`;

const StyledImg = styled(DynamicImage)`
  width: 4.14144rem;
  margin-right: 1rem;
  @media ${tablet} {
    width: 2.48488rem;
    margin-right: 0;
  }
`;

const MultipleUpsellLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
